import $ from "jquery";
import { useState } from "react";
import { Menu, X } from "react-feather";
import { NavLink } from "react-router-dom";
import logo from '../assets/img/small_logo.png';

const MobileMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const btn_Menu = () => {
        if (isOpen) {
            $('.menu_items').removeClass('slide_right').addClass('slide_left');
            $('.menu_shadow').removeClass('show').addClass('hide');
            $('.mobile_menu').removeClass('show');
        }
        else {
            $('.menu_items').removeClass('slide_left').addClass('slide_right');
            $('.menu_shadow').removeClass('hide').addClass('show');
            $('.mobile_menu').addClass('show');
        }
        setIsOpen(!isOpen);
        // $('.menu_items').scrollIntoView();
        // $('.menu_items').animate({
        //     transform : translateX("0"),
        // })
    }
    return (
        <>
            <div className="top-bar">
                <NavLink to={'/'} exact className="mobile_logo">
                    <img src={logo} alt="Logo"/>
                    {/*<span>GREENSIDE DEVELOPMENTS</span>*/}
                </NavLink>
                <button className='btn_menu' onClick={btn_Menu}>
                    {
                        isOpen ? <X/> : <Menu/>
                    }

                </button>
            </div>
            <div className="mobile_menu">
                <div className="menu_shadow"/>
                <div className="menu_items">

                    <ul>
                        <li>
                            <span>01/</span>
                            <NavLink exact to={'/'}>Greenside Development</NavLink>
                        </li>
                        <li>
                            <span>02/</span>
                            <NavLink exact to={'/communities'}>COMMUNITIES</NavLink>
                        </li>
                        <li>
                            <span>03/</span>
                            <NavLink exact to={'/project/wadi645'}>WADI 645</NavLink>
                        </li>
                        {/*<li>*/}
                        {/*    <NavLink exact to={'/team'}>Our Team</NavLink>*/}
                        {/*</li>*/}
                        <li>
                            <span>04/</span>
                            <NavLink exact to={'/contact'}>Contact</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default MobileMenu;