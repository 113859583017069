import $ from 'jquery';
import { useEffect, useState } from "react";
import { ArrowDown } from "react-feather";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import Lightbox from "yet-another-react-lightbox";
import vid from '../assets/videos/02.webm';
import LazyVideo from "../components/LazyVideo";
import Sidebar from "../components/Sidebar";
import { default as img_01, default as img_14 } from './../../src/assets/img/wadi/01.webp';
import LINK from "./../../src/components/LINK";
import logo from './../assets/img/small_logo.png';
import LINKClient from "./../components/LINKR";

const Commnites = () => {
    const [modalShow, setModalShow] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    const [open, setOpen] = useState(false);
    const [slides, setSlides] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [downloadLink, setDownloadLink] = useState('');
    const [modalImageShow, setModalImageShow] = useState(false);
    const changeModalShow = (value) => {
        setModalShow(value);
      };
      const sendMail = async () => {
        // const url = "http://192.168.242.193/React%20Naitve%20Mobile%20Apps/greenside_development/mail.php";
        const url = LINK;
        const url_response = LINKClient;

        let name = document.getElementById("name-p");
        name = name.value;
        let email = document.getElementById("email-p");
        email = email.value;
        let phone = document.getElementById("phone-p");
        phone = phone.value;
        if (name === "" || email === "" || phone === "") {
            toast.warn("All fields are required.");
        } else {
            try {
                let button = document.getElementById("btnSubmit");
                button.innerText = "Processing...";
                let formdata = new FormData();
                formdata.append("name", name);
                formdata.append("mail", email);
                formdata.append("email", "info@greenside.ae");
                formdata.append("phone", phone);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };
                fetch(`${url_response}`, requestOptions)
                .then(response => {
                   
                    response.text()}
                )
                .then(result => {
                    console.log(result);
                   
                }).catch(error => console.log('error', error));
                await fetch(`${url}`, requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        console.log(result);
                        if (result == 1) {
                            let name = document.getElementById("name");
                            name.value = "";
                            let email = document.getElementById("email");
                            email.value = "";
                            let phone = document.getElementById("phone");
                            phone.value = "";
                            button.innerText = "Submit";
                            toast.success("Your information has been shared with our team.");
                        } else {
                            toast.error("Server Error! Please try again later.")
                        }
                    }).catch(error => console.log('error', error));
            } catch (e) {
                console.log(e)
            }
        }
    }
    useEffect(() => {
        let prevScroll = 0;
        const handleScroll = () => {
            const newscrollTop = window.scrollY;
            setScrollTop(newscrollTop);
        };
        // Add scroll event listener when component mounts
        window.addEventListener("scroll", handleScroll)
        // Remove scroll event listener when component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll)
        };
    }, []);
    const scrollContentStyle = {
        opacity: scrollTop / 550 // Adjust opacity based on scroll position
    };
    const scrollDownAnimated = () => {
        $('html, body').animate({scrollTop: window.innerHeight});
    }
    const openModal = (url) => {
        setDownloadLink(url);
        $('.modal_background').removeClass('hide').addClass('show');
    }
    return (
        <div className="project_container">
            <Helmet>
                            <meta charSet="utf-8" />
                            <title>Greenside Development: Dubai's Prime Communities</title>
                            <meta name="title" content="Greenside Development: Dubai's Prime Communities"/>
                            <meta name="description" content="Explore Greenside Development's exclusive communities in Dubai, offering luxury living and unparalleled investment opportunities."/>
                            <meta name="keywords" content="Dubai, Communities Dubai, Real Estate Dubai"/>
                            <meta name="robots" content="index, follow"/>
                            <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                            <meta name="language" content="English"/>
                            <meta name="twitter:title" content="Greenside Development: Dubai's Prime Communities"/>
                            <meta name="twitter:description" content="Explore Greenside Development's exclusive communities in Dubai, offering luxury living and unparalleled investment opportunities."/>
                            <meta name="twitter:image" content={img_01}/>
                            <meta name="twitter:card" content={img_01}/>
            </Helmet>
            <LazyVideo videoSrc={vid}/>
            {/*<video className="home_background_video" playsInline={true} autoPlay loop muted>*/}
            {/*    <source src={vid} type="video/mp4"/>*/}
            {/*    /!* Provide fallback content in case the video cannot be played *!/*/}
            {/*    Your browser does not support the video tag.*/}
            {/*</video>*/}
            <Sidebar/>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={slides}
            />
            <div className="scroll_down_content">
                <button onClick={scrollDownAnimated}><ArrowDown/>
                    {/*<span>Scroll Down</span>*/}
                </button>
            </div>
            <div className="scroll_content">
                <div className="team_cards">
                    <div className="community_details">
                        {/*<div className="project_details_margin"/>*/}
                        <div className="block">
                            <div className="heading">
                                {/*<button onClick={() => scrollDownAnimated()}>Top</button>*/}
                                <h4>Greenside Communities</h4>
                                <p>At Greenside, our approach centers on community methodology. Our designs and actions are guided by the needs and values of our community. We believe in building spaces that reflect and enhance the essence of our community, ensuring they are inclusive, vibrant, and conducive to collective well-being.
                                </p>
                            </div>
                        </div>
                        <div className="block community_images">
                        <a     >

                                    <img src={img_14} loading={"lazy"} alt="WAdi 645"/>
                                </a>
                            
                        </div>
                        <div className="block ">
                            <div className="heading">
                                <h4>DEVELOPMENT AND CUSTOMER MANAGEMENT BY:</h4>
                            </div>
                            <img src={logo} alt="Jad Global" style={{marginTop: 10, width: 60}}/>
                        </div>
                        <div className="block">
                            <div className="heading">
                                <h4>GREENSIDE DEVELOPMENT</h4>
                                <p>
                                    Our team's mission is to create and enrich modern lifestyles. We approach our
                                    projects with a focus on emotional well-being, promoting a clean, minimalist
                                    community lifestyle tailored to our clients' needs.
                                </p>
                               
                            </div>
                        </div>
                        
                      
                       
                      
                </div>
                
            </div>






            {/*<div className="footer_content">*/}
            {/*    <p>Greenside Developments, situated in Dubai, features a team passionate about lifestyle, minimalist*/}
            {/*        living, and fostering a strong cultural ethos.*/}
            {/*        We prioritize giving back through meticulously designed spaces, embodying humble luxury.</p>*/}
            {/*</div>*/}
            {
                // window.innerWidth < 500 ?
                //     <div className="footer_content">
                //         <p>At Greenside, our approach centers on community methodology. Our designs and actions are
                //             guided by the needs and values of our community. We believe in building spaces that
                //             reflect and enhance the essence of our community, ensuring they are inclusive, vibrant,
                //             and conducive to collective well-being.
                //         </p>
                //         <TermsAndSocial />
                //     </div> : null
            }
        </div>
        </div>
    )
}

export default Commnites;