import $ from 'jquery';
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Lightbox from "yet-another-react-lightbox";
import Sidebar from "../components/Sidebar";
import { default as img_01 } from './../../src/assets/img/wadi/01.webp';

const PrivacyPolicy = () => {
    const [scrollTop, setScrollTop] = useState(0);
    const [open, setOpen] = useState(false);
    const [slides, setSlides] = useState([]);

    
    
    useEffect(() => {
        let prevScroll = 0;
        const handleScroll = () => {
            const newscrollTop = window.scrollY;
            setScrollTop(newscrollTop);
        };
        // Add scroll event listener when component mounts
        window.addEventListener("scroll", handleScroll)
        // Remove scroll event listener when component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll)
        };
    }, []);
    const scrollContentStyle = {
        opacity: scrollTop / 550 // Adjust opacity based on scroll position
    };
    const scrollDownAnimated = () => {
        $('html, body').animate({scrollTop: window.innerHeight});
    }
    
    return (
        <div className="project_container">
            <Helmet>
                            <meta charSet="utf-8" />
                            <title>Privacy Policy</title>
                            <meta name="title" content="Privacy Policy"/>
                            <meta name="description" content=""/>
                            <meta name="keywords" content="Dubai, Communities Dubai, Real Estate Dubai"/>
                            <meta name="robots" content="index, follow"/>
                            <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                            <meta name="language" content="English"/>
                            <meta name="twitter:title" content="Privacy Policy"/>
                            <meta name="twitter:description" content=""/>
                            <meta name="twitter:image" content={img_01}/>
                            <meta name="twitter:card" content={img_01}/>
            </Helmet>
            {/* <LazyVideo videoSrc={vid}/> */}
            {/*<video className="home_background_video" playsInline={true} autoPlay loop muted>*/}
            {/*    <source src={vid} type="video/mp4"/>*/}
            {/*    /!* Provide fallback content in case the video cannot be played *!/*/}
            {/*    Your browser does not support the video tag.*/}
            {/*</video>*/}
            <Sidebar/>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={slides}
            />
            
            <div className="">
                <div className="team_cards">
                    <div className="community_details">
                        {/*<div className="project_details_margin"/>*/}
                        <div className="block">
                            <div className="heading" style={{padding:5}}>

                                {/*<button onClick={() => scrollDownAnimated()}>Top</button>*/}
                                <h4>Privacy Policy</h4>
                                <p>Greenside development is committed to protecting your privacy. This policy is designed to ensure your personal details are protected when you deal with us.
                                </p>
                                <p>
                                In order to contact you and provide services to you, we need to collect some “personal information” about you. For example, we will need your name, address and email address when you register with us, and when you enter your name, address and/or email address in order to obtain more information about our products and services.
                                </p>
                                <p>
                                Greenside development only collects personal information that we need to provide you with a service. We will only use your personal information lawfully and will never collect nor share any sensitive information about you through this website without your express written permission.
                                </p>
                                <p>
                                We may use your personal information to market third party products and services to you. However, we shall obtain your permission prior to providing your details to such third parties, and every time we email you for third party marketing purposes, we will give you the chance to refuse any such emails in the future.
                                </p>
                            </div>
                        </div>
                </div>
                
            </div>

        </div>
        </div>
    )
}

export default PrivacyPolicy;