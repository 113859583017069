import { Helmet } from "react-helmet";
import vid from '../assets/videos/01.webm';
import LazyVideo from "../components/LazyVideo";
import Sidebar from "../components/Sidebar";
import TermsAndSocial from "../components/TermsAndSocial";
import img_01 from './../assets/img/wadi/01.webp';

const Home = () => {

    return (
        <div className="home_container">
            <Helmet>
                            <meta charSet="utf-8" />
                            <title>Greenside Development: Premier Dubai Real Estate | Luxury Properties & Investment Opportunities</title>
                            <meta name="title" content="Greenside Development: Premier Dubai Real Estate | Luxury Properties & Investment Opportunities"/>
                            <meta name="description" content="Discover luxury real estate opportunities in Dubai with Greenside Development. Explore our premier properties for investment and upscale living."/>
                            <meta name="keywords" content="Dubai, Communities Dubai, Real Estate Dubai"/>
                            <meta name="robots" content="index, follow"/>
                            <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                            <meta name="language" content="English"/>
                            <meta name="twitter:title" content="Greenside Development: Premier Dubai Real Estate | Luxury Properties & Investment Opportunities"/>
                            <meta name="twitter:description" content="Discover luxury real estate opportunities in Dubai with Greenside Development. Explore our premier properties for investment and upscale living."/>
                            <meta name="twitter:image" content={img_01}/>
                            <meta name="twitter:card" content={img_01}/>
            </Helmet>
            <LazyVideo videoSrc={vid}/>
            <Sidebar/>
            {
                window.innerWidth < 500 ?
                    <div className="footer_content">
                        <p>At Greenside Development, collaboration is key. We partner with teams that share our vision
                            of
                            community giving and prioritizing lifestyle over mere projects. Our focus extends beyond
                            development;</p>
                        <p style={{marginTop : "0.5rem"}}>It's  about  enriching  lives and fostering sustainable, community-centric environments that
                            resonate with our collective values.</p>
                        <TermsAndSocial />
                    </div> : null
            }

        </div>
    )
}

export default Home;