import React, {useRef, useEffect} from 'react';

const LazyVideo = ({videoSrc}) => {
    const videoRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const video = entry.target;
                    video.load(); // Load the video when it is in view
                    observer.unobserve(video); // Stop observing the video
                }
            });
        });

        observer.observe(videoRef.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <video ref={videoRef} className="home_background_video" preload={'auto'} playsInline={true} autoPlay loop muted>
            <source src={videoSrc} type="video/webm"/>
            Your browser does not support the video tag.
        </video>
    );
};

export default LazyVideo;
