

import { useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import CarouselImage from '../components/CarouselImage';

export default function ImagesModal({list,show,onStateChange})  {
    const [isShow, setShow] = useState(true);

    // useEffect(() => {
    //     if (show) {
    //         setShow(true);
    //     } else {
    //         setShow(false);
    //     }
      

    // }, []);
    const handleClose =   () => {setShow(!isShow)};
    return (
        <Modal  show={show} fullscreen={true} className='modal_image_background' size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        tabIndex='-1'
        role='dialog'
     
        aria-hidden='true'
        >

        <Modal.Dialog>
        <Modal.Header closeButton  onHide={onStateChange}>
         
        </Modal.Header>

        <Modal.Body>
        <Carousel fade>
                {list.map(function(object, i){
                    return  <Carousel.Item>
                                <CarouselImage text="Wadi645" url={object} />
                                <Carousel.Caption>
                                {/* <h3>First slide label</h3>
                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                                </Carousel.Caption>
                            </Carousel.Item>;
                    })}
                </Carousel>
        </Modal.Body>

        {/* <Modal.Footer>
          <Button variant="secondary"  >Close</Button>
          <Button variant="primary">Save changes</Button>
        </Modal.Footer> */}
      </Modal.Dialog>
      </Modal>
      
        
       
      );
}