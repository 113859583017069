import $ from 'jquery';
import { X } from "react-feather";
import { toast } from "react-toastify";
import LINK from "./../components/LINK";
import LINKClient from "./../components/LINKR";

export default function ContactModal({downloadURL}) {

    const sendMail = async () => {

        const url = LINK;
        const url_response = LINKClient;

        let name = document.getElementById("name");
        name = name.value;
        let email = document.getElementById("email");
        email = email.value;
        let phone = document.getElementById("phone");
        phone = phone.value;
        if (name === "" || email === "" || phone === "") {
            toast.warn("All fields are required.");
        } else {
            try {
                let button = document.getElementById("btnSubmit");
                button.innerText = "Processing...";
                let formdata = new FormData();
                formdata.append("name", name);
                formdata.append("mail", email);
                formdata.append("email", "info@greenside.ae");
                formdata.append("phone", phone);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };
                //
                 fetch(`${url_response}`, requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        console.log(result);
                        
                    }).catch(error => console.log('error', error));
                
                await fetch(`${url}`, requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        console.log(result);
                        if (result ==='1') {
                            let name = document.getElementById("name");
                            name.value = "";
                            let email = document.getElementById("email");
                            email.value = "";
                            let phone = document.getElementById("phone");
                            phone.value = "";
                            button.innerText = "Submit";
                            $('.modal_background').removeClass('show').addClass('hide');
                             window.open(downloadURL, "_blank");
                            fetch(downloadURL).then((response) => {
                                response.blob().then((blob) => {
                            
                                    // Creating new object of PDF file
                                    const fileURL =
                                        window.URL.createObjectURL(blob);
                                    
                                    // Setting various property values
                                    let alink = document.createElement("a");
                                    alink.href = fileURL;
                                    alink.download = "WADI645.pdf";
                                    alink.click();
                                });
                            });
                             toast.success("Your information has been shared with our team.");
                        } else {
                            toast.error("Server Error! Please try again later.")
                        }
                    }).catch(error => console.log('error', error));
                
            } catch (e) {
                console.log(e)
            }
        }
    }
    const closeModal = () => {
        $('.modal_background').removeClass('show').addClass('hide');
    }
    // const openModal = () => {
    //     $('.modal_background').removeClass('hide').addClass('show');
    // }
    return (
        <div className="modal_background hide">
            <div className="contact-form">
                <button className='close' onClick={closeModal}><X/></button>
                <h3 className="title">Get In Touch</h3>
                <p style={{fontSize: 12, textAlign: "center"}}>Discover Your Perfect with Ease! Share your preferences with us, and we'll swiftly match you with the best options available. Your dream home awaits!</p>
                <div className="input-block">
                    <label>Full Name <code>*</code></label>
                    <input type="text" id='name' placeholder={'Enter Full Name'}/>
                </div>
                <div className="input-block">
                    <label>Email Address <code>*</code></label>
                    <input type="text" id='email' placeholder={'Enter your email'}/>
                </div>
                <div className="input-block">
                    <label>Contact No. <code>*</code></label>
                    <input type="text" id='phone' placeholder={'Enter contact no.'}/>
                </div>
                <div className="checkbox-block">
                    <input type="checkbox"/>
                    <span>Keep me updated on news & offers.</span>
                </div>
                <div className="input-block " >
                    <button className='btn-submit' id='btnSubmit' onClick={sendMail}>Submit</button>
                </div>
            </div>
        </div>
    )
}