import $ from 'jquery';
import { useEffect, useState } from "react";
import { Instagram, Linkedin, Menu, X } from "react-feather";
import { NavLink } from "react-router-dom";
import logo from '../assets/img/logo.png';
import MobileMenu from "./MobileMenu";

const Sidebar = () => {
    const [isHome, setIsHome] = useState(true);
    const [isCommunities, setIsCommunities] = useState(false);
    const [isContact, setIsContact] = useState(false);
    // const btn_close_click = () => {
    //     $('#menu').slideUp("slow", function () {
    //         $('.btn_open_container').slideDown("slow");
    //     })
    // }
    // const btn_open_click = () => {
    //     $('.btn_open_container').slideUp("slow");
    //     $('#menu').slideDown("slow")
    // }
    useEffect(() => {
        let href = window.location.href;
        let source = href.split("/");
        source = source[3];
        if (source == null || source == "") {
            setIsHome(true);
        } else {
            setIsHome(false);
        }
        if (source == "communities") {
            setIsCommunities(true);
        } else {
            setIsCommunities(false);
        }
        if (source == "contact") {
            setIsContact(true);
        } else {
            setIsContact(false);
        }

    }, [])

    const [isOpen, setIsOpen] = useState(false);
    const btn_Menu = () => {
        if (isOpen) {
            $('.menu_items').removeClass('slide_right').addClass('slide_left');
            $('.menu_shadow').removeClass('show').addClass('hide');
            $('.mobile_menu').removeClass('show');
        } else {
            $('.mobile_menu').addClass('show').addClass('desktop');
            $('.menu_items').removeClass('slide_left').addClass('slide_right');
            $('.menu_shadow').removeClass('hide').addClass('show');

        }

        setIsOpen(!isOpen);
        // $('.menu_items').scrollIntoView();
        // $('.menu_items').animate({
        //     transform : translateX("0"),
        // })
    }


    return (
        <>
            <MobileMenu/>
            <button className='btn_desktop_menu' onClick={btn_Menu}>
                {
                    isOpen ? <X/> : <Menu/>
                }

            </button>
            <div className="sidebar">
                <div className="card_nav"  >
                <NavLink to={'/'} exact className="logo">
                    <img src={logo} alt="Brand Logo"/>
                    {/*<span>GREENSIDE DEVELOPERS</span>*/}
                </NavLink>
                <div className="menu" id="menu">
                    <ul>
                        <li>
                            <NavLink exact to={'/'}>Greenside Development</NavLink>
                        </li>
                        <li>
                            <NavLink exact to={'/communities'}>COMMUNITIES</NavLink>
                        </li>
                        <li>
                            <NavLink exact to={'/project/wadi645'}>WADI 645  <span style={{fontSize:10,}}>( WADI AL SAFA 3, AL BARARI )</span></NavLink>
                        </li>
                        <li>
                            <NavLink exact to={'/contact'}>Contact</NavLink>
                        </li>
                    </ul>
                </div>
                </div>
               
                <div className="sidebar_content">
                    {
                        isHome ? <>
                            <p>At Greenside Development, collaboration is absolutely key. We partner with teams that truly share our vision of community giving and prioritizing lifestyle over mere development projects. Our focus extends beyond development; </p>
                            <p style={{marginTop: "0.5rem"}}>It's &nbsp;&nbsp; about &nbsp;&nbsp;&nbsp;enriching&nbsp;&nbsp; lives&nbsp;&nbsp; and fostering sustainable, community-centric environments that genuinely resonate deeply with our shared collective values and aspirations.</p>
                        </> : null
                    }
                    {
                        isCommunities ? <>
                            {/* <p>At Greenside, our approach centers on community methodology. Our designs and actions are
                                guided by the needs and values of our community. We believe in building spaces that
                                reflect and enhance the essence of our community, ensuring they are inclusive, vibrant,
                                and conducive to collective well-being.
                            </p> */}
                        </> : null
                    }
                    {
                        isContact ? <>
                            <p>Greenside Development LLC</p>
                           
                                <p>Tel:&nbsp;<a href="tel:+97143251616">+971 4 325 1616</a></p>
                                <p>Mob:&nbsp;<a href="tel:+971509166543">+971 50 916 6543</a></p>
                            <a href="mailto:info@greenside.ae">info@greenside.ae</a>
                        </> : null
                    }

                    <div className="sidebar_link" style={{marginTop: "0.7rem"}}>
                        <a href="https://www.linkedin.com/company/greenside-development-ae/"  target={'_blank'}><Linkedin/></a>
                        {/*<a href="#"><Youtube/></a>*/}
                        {/*<a href="#"><Twitter/></a>*/}
                        <a href="https://www.instagram.com/greenside.ae/" target={'_blank'}><Instagram/></a>
                    </div>
                    <div className="sidebar_terms">
                        <h6>TERMS AND CONDITIONS:</h6>
                        <p style={{fontSize:10}}>The content of this website, including but not limited to text, graphics, logos, images,
                            audio clips, digital downloads, and software, is the property of Copyright 2023
                            EC-01-003912/ EC-01-003918 or its content suppliers and is protected by international
                            copyright laws. <NavLink exact to={'/privacy-policy'}>Privacy Policy</NavLink>

                        </p>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Sidebar;