import $ from 'jquery';
import { useEffect, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import BROCHURES_AR from '../../assets/WADI 645 - AR Brochure.pdf';
import BROCHURES from '../../assets/WADI 645 -EN Brochure.pdf';
import logo from '../../assets/img/small_logo.png';
import Sidebar from "../../components/Sidebar";
import '../../scripts/css/no-scrollbaar.css';
//Partners
import dyar from '../../assets/img/wadi/dyar.png';
import harbor from '../../assets/img/wadi/harbor.png';
import jad_global from '../../assets/img/wadi/jad_global.png';
//Project Images
import img_01 from '../../assets/img/wadi/01.webp';
import img_02 from '../../assets/img/wadi/02.webp';
import img_03 from '../../assets/img/wadi/03.webp';
import img_04 from '../../assets/img/wadi/04.webp';
import img_05 from '../../assets/img/wadi/05.webp';
import img_06 from '../../assets/img/wadi/06.webp';
import img_07 from '../../assets/img/wadi/07.webp';
import img_08 from '../../assets/img/wadi/08.webp';
import img_09 from '../../assets/img/wadi/09.webp';
import img_10 from '../../assets/img/wadi/10.webp';

import { ArrowDown } from "react-feather";
import img_12 from '../../assets/img/wadi/12.webp';
import img_13 from '../../assets/img/wadi/13.webp';
import img_14 from '../../assets/img/wadi/14.webp';
import img_15 from '../../assets/img/wadi/15.webp';
import img_16 from '../../assets/img/wadi/16.webp';
//Brochure
import BED_1 from '../../assets/APARTMENT BROCHURE/BEDROOM TYPE 1.pdf';
import BED_2 from '../../assets/APARTMENT BROCHURE/BEDROOM TYPE 2.pdf';
import BED_1_A from '../../assets/APARTMENT BROCHURE/BEDROOM TYPE A.pdf';
import BED_1_B1 from '../../assets/APARTMENT BROCHURE/BEDROOM TYPE B1.pdf';
import BED_1_B2 from '../../assets/APARTMENT BROCHURE/BEDROOM TYPE B2.pdf';
import BED_1_C1 from '../../assets/APARTMENT BROCHURE/BEDROOM TYPE C1.pdf';
import BED_1_C2 from '../../assets/APARTMENT BROCHURE/BEDROOM TYPE C2.pdf';
import BED_1_D1 from '../../assets/APARTMENT BROCHURE/BEDROOM TYPE D1.pdf';
import BED_1_D2 from '../../assets/APARTMENT BROCHURE/BEDROOM TYPE D2.pdf';
import STUDIO from '../../assets/APARTMENT BROCHURE/STUDIO.pdf';
import vid from "../../assets/videos/project.webm";
import LazyVideo from "../../components/LazyVideo";
// WhatsApp Image
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import whatsApp from '../../assets/img/whatsApp.png';
import ContactModal from "../../components/ContactModal";
import ImagesModal from "../../components/ImagesModal";
import LINK from "./../../components/LINK";
import LINKClient from "./../../components/LINKR";

const Wadi645 = () => {
    const [modalShow, setModalShow] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    const [open, setOpen] = useState(false);
    const [slides, setSlides] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [downloadLink, setDownloadLink] = useState('');
    const [modalImageShow, setModalImageShow] = useState(false);
    const changeModalShow = (value) => {
        setModalShow(value);
      };
      const sendMail = async () => {
        // const url = "http://192.168.242.193/React%20Naitve%20Mobile%20Apps/greenside_development/mail.php";
        const url = LINK;
        const url_response = LINKClient;

        let name = document.getElementById("name-p");
        name = name.value;
        let email = document.getElementById("email-p");
        email = email.value;
        let phone = document.getElementById("phone-p");
        phone = phone.value;
        if (name === "" || email === "" || phone === "") {
            toast.warn("All fields are required.");
        } else {
            try {
                let button = document.getElementById("btnSubmit");
                button.innerText = "Processing...";
                let formdata = new FormData();
                formdata.append("name", name);
                formdata.append("mail", email);
                formdata.append("email", "info@greenside.ae");
                formdata.append("phone", phone);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };
                fetch(`${url_response}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    console.log(result);
                    
                }).catch(error => console.log('error', error));
            
                await fetch(`${url}`, requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        console.log(result);
                        if (result == 1) {
                            let name = document.getElementById("name");
                            name.value = "";
                            let email = document.getElementById("email");
                            email.value = "";
                            let phone = document.getElementById("phone");
                            phone.value = "";
                            button.innerText = "Submit";
                            toast.success("Your information has been shared with our team.");
                        } else {
                            toast.error("Server Error! Please try again later.")
                        }
                    }).catch(error => console.log('error', error));
            } catch (e) {
                console.log(e)
            }
        }
    }
    const imageList = [img_01,img_02,img_03,img_04,img_05];
    useEffect(() => {
        let prevScroll = 0;
        const handleScroll = () => {
            const newscrollTop = window.scrollY;
            setScrollTop(newscrollTop);
        };
        // Add scroll event listener when component mounts
        window.addEventListener("scroll", handleScroll)
        // Remove scroll event listener when component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll)
        };
    }, []);

    const scrollContentStyle = {
        opacity: scrollTop / 550 // Adjust opacity based on scroll position
    };
    const scrollDownAnimated = () => {
        $('html, body').animate({scrollTop: window.innerHeight});
    }
    const openModal = (url) => {
        setDownloadLink(url);
        $('.modal_background').removeClass('hide').addClass('show');
    }
    const openCarouselModal = () => {
        setModalShow(true);
        $('#modal-image').removeClass('hide').addClass('show');

    }
    return (

        <div className="project_container">
             <Helmet>
                            <meta charSet="utf-8" />
                            <title>Wadi 645: Luxury Living in Dubai by Greenside</title>
                            <meta name="title" content="Wadi 645: Luxury Living in Dubai by Greenside"/>
                            <meta name="description" content="Experience unparalleled luxury living at Wadi 645 by Greenside Development in Dubai. Explore stunning residences and premium amenities. Your dream lifestyle awaits!"/>
                            <meta name="keywords" content="Dubai, Communities Dubai, Real Estate Dubai"/>
                            <meta name="robots" content="index, follow"/>
                            <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                            <meta name="language" content="English"/>
                            <meta name="twitter:title" content="Wadi 645: Luxury Living in Dubai by Greenside"/>
                            <meta name="twitter:description" content="Experience unparalleled luxury living at Wadi 645 by Greenside Development in Dubai. Explore stunning residences and premium amenities. Your dream lifestyle awaits!"/>
                            <meta name="twitter:image" content={img_01}/>
                            <meta name="twitter:card" content={img_01}/>
            </Helmet>
            {/*<div className="project_container_background"*/}
            {/*         style={{backgroundImage: window.innerWidth < 500 ? `url(${wadi645_mobile})` : `url(${wadi645})`}}/>*/}
            {/*<div className="home_container">*/}
            <LazyVideo videoSrc={vid}/>
            <ContactModal downloadURL={downloadLink}/>
            <ImagesModal list={imageList}  show={modalShow} onStateChange={changeModalShow}/>
            <Sidebar/>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={slides}
            />
            <div className="scroll_down_content">
                <button onClick={scrollDownAnimated}><ArrowDown/>
                    {/*<span>Scroll Down</span>*/}
                </button>
            </div>
            <div className="scroll_content">
                <div className="team_cards">
                    <div className="project_details">
                        {/*<div className="project_details_margin"/>*/}
                        <div className="block">
                            <div className="heading">
                                {/*<button onClick={() => scrollDownAnimated()}>Top</button>*/}
                                <h4>ABOUT THE PROJECT</h4>
                                <p>Welcome to the unveiling of <b>Wadi 645</b>, nestled in the tranquil green surroundings of
                                    Al Barari, Wadi Al Safa 3 Zone, Dubai. Our project is dedicated to giving back to
                                    the community by fostering a lifestyle centered on sustainable and minimalist living
                                    for enhanced mental and physical health. Development and customer management are by
                                    Greenside Development and Jad Global, with post-completion experience powered by
                                    Deyaar’s
                                    community management. Marketing and sales are powered by Harbor Real Estate. Wadi 645
                                    represents a new movement in development projects, embracing a 'less is more'
                                    approach from design to brand experience, We invite you to join us in embracing
                                    minimalist living and discovering the joy of owning less to live more. From the very
                                    start of the design process to the management of facilities, Wadi 645 promises an
                                    experience where simplicity meets sophistication. Welcome to a new era of living at
                                    Wadi 645.
                                </p>
                            </div>
                        </div>
                        <div className="block">
                            <div className="heading">
                                <h4 style={{display: 'flex'}}>PROJECT BROCHURES DOWNLOAD&nbsp;<a
                                    href='javascript:void(0)' onClick={() => {
                                    openModal(BROCHURES_AR)
                                }}>( AR.
                                    )</a>&nbsp;<a href='javascript:void(0)' onClick={() => {
                                    openModal(BROCHURES)
                                }}>( EN. )</a></h4>
                                <p>Our focus is on minimalist living and style, embracing the "less is more" philosophy
                                    to suit modern lifestyles and compact living spaces. We prioritize simplicity,
                                    functionality, and efficient use of space, enabling a mindful and sustainable
                                    approach to daily life.
                                </p>
                                <button onClick={() => {
                                    openModal(BROCHURES_AR)
                                }}  className='btn-brochure'>عرض الكتيب (AR.)
                                </button>
                                <button onClick={() => {
                                    openModal(BROCHURES)
                                }} className='btn-brochure-dark'>View Brochure (EN.)
                                </button>
                        
                                 
                            </div>
                        </div>
                        <div className="block">
                            <div className="heading">
                                <h4>APARTMENT TYPES</h4>
                                <p>Our apartment designs are meticulously crafted, inside and out, with a profound
                                    understanding that we spend 90% of our time indoors. Every corner is thoughtfully
                                    considered to support and enhance our lifestyle.
                                </p>
                                {/*<h4><a href={STUDIO} download={false} target={'_blank'}><h4>STUDIO UNIT</h4>*/}
                                {/*    <span>See More</span></a></h4>*/}
                                <hr></hr>

                                <h4><a href='javascript:void(0)' onClick={() => {
                                    openModal(STUDIO)
                                }}>
                                    
                                    <h4>STUDIO UNIT</h4>
                                    <span ><i>View Info</i></span>
                                    </a>
                                </h4>
                                <hr></hr>
                                <h4><a href='javascript:void(0)' onClick={() => {
                                    openModal(BED_1_A)
                                }}><h4>1 BEDROOM UNIT TYPE-A</h4>
                                    <span ><i>View Info</i></span></a>
                                </h4>
                               

                                <h4><a href='javascript:void(0)' onClick={() => {
                                    openModal(BED_1_B1)
                                }}><h4>1 BEDROOM UNIT
                                    TYPE-B1</h4><span ><i>View Info</i></span></a>
                                </h4>
                              

                                <h4><a href='javascript:void(0)' onClick={() => {
                                    openModal(BED_1_B2)
                                }}><h4>1 BEDROOM UNIT
                                    TYPE-B2</h4><span ><i>View Info</i></span></a>
                                </h4>
                                <h4><a href='javascript:void(0)' onClick={() => {
                                    openModal(BED_1_C1)
                                }}><h4>1 BEDROOM UNIT
                                    TYPE-C1</h4><span ><i>View Info</i></span></a>
                                </h4>
                                <h4><a href='javascript:void(0)' onClick={() => {
                                    openModal(BED_1_C2)
                                }}><h4>1 BEDROOM UNIT
                                    TYPE-C2</h4><span ><i>View Info</i></span></a>
                                </h4>
                                <h4><a href='javascript:void(0)' onClick={() => {
                                    openModal(BED_1_D1)
                                }}><h4>1 BEDROOM UNIT
                                    TYPE-D1</h4><span ><i>View Info</i></span></a>
                                </h4>
                                <h4><a href='javascript:void(0)' onClick={() => {
                                    openModal(BED_1_D2)
                                }}><h4>1 BEDROOM UNIT
                                    TYPE-D2</h4><span ><i>View Info</i></span></a>
                                </h4>
                                <hr></hr>
                                <h4><a href='javascript:void(0)' onClick={() => {
                                    openModal(BED_1)
                                }}><h4>2 BEDROOM UNIT TYPE-1</h4>
                                    <span ><i>View Info</i></span></a></h4>
                                <h4><a href='javascript:void(0)' onClick={() => {
                                    openModal(BED_2)
                                }}><h4>2 BEDROOM UNIT TYPE-2</h4>
                                    <span ><i>View Info</i></span></a></h4>
                                    <hr></hr>
                            </div>
                        </div>
                        <div className="block">
                            <div className="heading">
                                <h4>DEVELOPMENT AND CUSTOMER MANAGEMENT BY:</h4>
                            </div>
                            <img src={logo} alt="Jad Global" style={{marginTop: 10, width: 60}}/>
                        </div>
                        <div className="block">
                            <div className="heading">
                                <h4>GREENSIDE DEVELOPMENT</h4>
                                <p>
                                    Our team's mission is to create and enrich modern lifestyles. We approach our
                                    projects with a focus on emotional well-being, promoting a clean, minimalist
                                    community lifestyle tailored to our clients' needs.
                                </p>
                                <img src={jad_global} alt="Jad Global"
                                     style={{marginTop: 15, marginLeft: 15, width: 150}}/>
                            </div>
                        </div>
                        <div className="block">
                            <div className="heading">
                                <h4>JAD GLOBAL REAL ESTATE DEVELOPMENT</h4>
                                <p>
                                    Led by Dubai's seasoned real estate veterans, Jad Global Real Estate transcends mere
                                    development. We're visionaries, transforming every project – even smaller ones –
                                    into impactful landmarks. Our expertise goes beyond bricks and mortar. We craft
                                    opportunities to maximize your wealth and create unforgettable memories in your dream home.
                                </p>
                                {/*<img src={dyar} alt="Deyaar"/>*/}
                            </div>
                        </div>
                        <div className="block">
                            <div className="heading">
                                <h4>POST-COMPLETION EXPERIENCE POWERED BY:</h4>
                                <img src={dyar} alt="Deyaar"/>
                            </div>
                        </div>
                        <div className="block">
                            <div className="heading">
                                <h4>DEYAAR COMMUNITY MANAGEMENT</h4>
                                <p>
                                    Since our inception in 2009, Deyaar Community Management has been dedicated to
                                    delivering top-notch solutions. Our diverse team stands as a formidable force,
                                    equipped with extensive industry knowledge and unwavering technical prowess. With a
                                    portfolio encompassing over 10,000 units across 40 communities, Deyaar Community
                                    Management understands the exact standards of service and care necessary for
                                    fostering happy, vibrant communities.
                                </p>
                            </div>
                        </div>
                        <div className="block">
                            <div className="heading">
                                <h4>MARKETING AND SALES POWERED BY:</h4>
                                <img src={harbor} alt="Harbor Real Estate"/>
                            </div>
                        </div>
                        <div className="block">
                            <div className="heading">
                                <h4>HARBOR REAL ESTATE</h4>
                                <p>A trusted name in real estate for over 50 years. Our award-winning team caters to
                                    diverse clients, from public entities to individual investors. Recognized for
                                    exceptional service and market knowledge, we hold the prestigious Dubai Land
                                    Department Gold Ranking for seven consecutive years. Top publications like Forbes,
                                    Arabian Business and Property Times acknowledge our dedication. Let our experience
                                    be your guide. Achieve your real estate goals with Harbor Real Estate.</p>
                            </div>
                        </div>
                        
                        <div className="block">
                            <h3 className="title">Get In Touch</h3>
                            <p style={{fontSize: 12, textAlign: "center"}}>Discover Your Perfect with Ease! Share your preferences with us, and we'll swiftly match you with the best options available. Your dream home awaits!</p>
                            <div className="input-block">
                                <label>Full Name <code>*</code></label>
                                <input type="text" id='name-p' placeholder={'Enter Full Name'}/>
                            </div>
                            <div className="input-block">
                                <label>Email Address <code>*</code></label>
                                <input type="text" id='email-p' placeholder={'Enter your email'}/>
                            </div>
                            <div className="input-block">
                                <label>Contact No. <code>*</code></label>
                                <input type="text" id='phone-p' placeholder={'Enter contact no.'}/>
                            </div>
                            <div className="checkbox-block">
                                <input type="checkbox"/>
                                <span>Keep me updated on news & offers.</span>
                            </div>
                            <div className="input-block">
                            <button className='btn-submit' id='btnSubmit' onClick={sendMail}>Submit</button>
                            </div>
                        </div>
                        <div className="block">
                                <div className="heading contact">
                                <h4>Contact Us <a href="https://wa.me/+971509166543?text=I'm%20interested%20in%20WADI645" target={'_blank'}><img
                                    src={whatsApp} alt=""/></a></h4>
                                <p>Tel:&nbsp;<a href="tel:+97143251616">+971 4 325 1616</a></p>
                                <p>Mob:&nbsp;<a href="tel:+971509166543">+971 50 916 6543</a></p>
                                <p>Email:&nbsp;<a href="mailto:info@greenside.ae">info@greenside.ae</a></p>
                              
                            </div>
                        </div>
                    </div>
                    <div className="project_images">
                        <span className="vertical_content" style={scrollContentStyle}>Scroll For More Images</span>
                        {/*<span className="vertical_content_arrow"><ArrowLeft/></span>*/}
                        <a  href='javascript:void(0)' onClick={() => {openCarouselModal() }}  >
                            <img src={img_01} loading={"lazy"} alt="Project Images"/>
                        </a>
                        <a  href='javascript:void(0)' onClick={() => {openCarouselModal() }}  >
                            <img src={img_02} loading={"lazy"} alt="Project Images"/>
                        </a>
                        <a  href='javascript:void(0)' onClick={() => {openCarouselModal() }}  >
                            <img src={img_03} loading={"lazy"} alt="Project Images"/>
                        </a>
                        <a  href='javascript:void(0)' onClick={() => {openCarouselModal() }}  >
                            <img src={img_04} loading={"lazy"} alt="Project Images"/>
                        </a>
                        <a  href='javascript:void(0)' onClick={() => {openCarouselModal() }}  >
                            <img src={img_05} loading={"lazy"} alt="Project Images"/>
                        </a>
                        <a  href='javascript:void(0)' onClick={() => {openCarouselModal() }}  >
                            <img src={img_06} loading={"lazy"} alt="Project Images"/>
                        </a>
                        <a  href='javascript:void(0)' onClick={() => {openCarouselModal() }}  >
                            <img src={img_07} loading={"lazy"} alt="Project Images"/>
                        </a>
                        <a  href='javascript:void(0)' onClick={() => {openCarouselModal() }}  >
                            <img src={img_08} loading={"lazy"} alt="Project Images"/>
                        </a>
                        <a  href='javascript:void(0)' onClick={() => {openCarouselModal() }}  >
                            <img src={img_10} loading={"lazy"} alt="Project Images"/>
                        </a>
                        <a  href='javascript:void(0)' onClick={() => {openCarouselModal() }}  >
                            <img src={img_09} loading={"lazy"} alt="Project Images"/>
                        </a>
                        <a  href='javascript:void(0)' onClick={() => {openCarouselModal() }}  >
                            <img src={img_12} loading={"lazy"} alt="Project Images"/>
                        </a>
                        <a  href='javascript:void(0)' onClick={() => {openCarouselModal() }}  >
                            <img src={img_13} loading={"lazy"} alt="Project Images"/>
                        </a>
                        <a  href='javascript:void(0)' onClick={() => {openCarouselModal() }}  >
                            <img src={img_14} loading={"lazy"} alt="Project Images"/>
                        </a>
                        <a  href='javascript:void(0)' onClick={() => {openCarouselModal() }}  >
                            <img src={img_15} loading={"lazy"} alt="Project Images"/>
                        </a>
                        <a  href='javascript:void(0)' onClick={() => {openCarouselModal() }}  >
                            <img src={img_16} loading={"lazy"} alt="Project Images"/>
                        </a>
                    </div>
                    
                </div>

            </div>

        </div>
    )
}

export default Wadi645;