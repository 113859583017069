import vid from '../assets/videos/04.webm';
import Sidebar from "../components/Sidebar";

import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import LINK from "../components/LINK";
import LINKClient from "../components/LINKR";
import LazyVideo from "../components/LazyVideo";
import img_01 from './../assets/img/wadi/01.webp';

const Contact = () => {

    const sendMail = async () => {
        // const url = "http://192.168.242.193/React%20Naitve%20Mobile%20Apps/greenside_development/mail.php";
        const url = LINK;
        const url_response = LINKClient;

        let name = document.getElementById("name");
        name = name.value;
        let email = document.getElementById("email");
        email = email.value;
        let phone = document.getElementById("phone");
        phone = phone.value;
        if (name === "" || email === "" || phone === "") {
            toast.warn("All fields are required.");
        } else {
            try {
                let button = document.getElementById("btnSubmit");
                button.innerText = "Processing...";
                let formdata = new FormData();
                formdata.append("name", name);
                formdata.append("mail", email);
                formdata.append("email", "info@greenside.ae");
                formdata.append("phone", phone);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };
                 fetch(`${url_response}`, requestOptions)
                .then(response => {
                   
                    response.text()}
                )
                .then(result => {
                    console.log(result);
                   
                }).catch(error => console.log('error', error));
            
                await fetch(`${url}`, requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        console.log(result);
                        if (result == 1) {
                            let name = document.getElementById("name");
                            name.value = "";
                            let email = document.getElementById("email");
                            email.value = "";
                            let phone = document.getElementById("phone");
                            phone.value = "";
                            button.innerText = "Submit";
                            toast.success("Your information has been shared with our team.");
                        } else {
                            toast.error("Server Error! Please try again later.")
                        }
                    }).catch(error => console.log('error', error));
            } catch (e) {
                console.log(e)
            }
        }
    }

    return (
        <div className="home_container">
             <Helmet>
                            <meta charSet="utf-8" />
                            <title>Get in Touch: Contact Greenside Development for Dubai Real Estate</title>
                            <meta name="title" content="Get in Touch: Contact Greenside Development for Dubai Real Estate"/>
                            <meta name="description" content="Reach out to Greenside Development for inquiries about luxury real estate in Dubai. Our team is here to assist you with your property needs. Contact us today!"/>
                            <meta name="keywords" content="Dubai, Communities Dubai, Real Estate Dubai"/>
                            <meta name="robots" content="index, follow"/>
                            <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                            <meta name="language" content="English"/>
                            <meta name="twitter:title" content="Get in Touch: Contact Greenside Development for Dubai Real Estate"/>
                            <meta name="twitter:description" content="Reach out to Greenside Development for inquiries about luxury real estate in Dubai. Our team is here to assist you with your property needs. Contact us today!"/>
                            <meta name="twitter:image" content={img_01}/>
                            <meta name="twitter:card" content={img_01}/>
                            

            </Helmet>
            <LazyVideo videoSrc={vid}/>
            {/*<video className="home_background_video" playsInline={true} autoPlay loop muted>*/}
            {/*    <source src={vid} type="video/mp4"/>*/}
            {/*    /!* Provide fallback content in case the video cannot be played *!/*/}
            {/*    Your browser does not support the video tag.*/}
            {/*</video>*/}
            <Sidebar/>
            {/*<div className="footer_content">*/}
            {/*    <div className="row justify-content-center">*/}
            {/*        <ul className="footer_links">*/}
            {/*            <li>*/}
            {/*                <a href="#"><Facebook/></a>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <a href="#"><Youtube/></a>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <a href="#"><Twitter/></a>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <a href="#"><Instagram/></a>*/}
            {/*            </li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*    <p>All copy &copy; rights are reserved by <h4>GREENSIDE DEVELOPMENTS</h4></p>*/}
            {/*</div>*/}

            <div className="contact-form">
                <h3 className="title">Get In Touch</h3>
                <p style={{fontSize: 12, textAlign: "center"}}>Discover Your Perfect with Ease! Share your preferences with us, and we'll swiftly match you with the best options available. Your dream home awaits!</p>
                <div className="input-block">
                    <label>Full Name <code>*</code></label>
                    <input type="text" id='name' placeholder={'Enter Full Name'}/>
                </div>
                <div className="input-block">
                    <label>Email Address <code>*</code></label>
                    <input type="text" id='email' placeholder={'Enter your email'}/>
                </div>
                <div className="input-block">
                    <label>Contact No. <code>*</code></label>
                    <input type="text" id='phone' placeholder={'Enter contact no.'}/>
                </div>
                <div className="checkbox-block">
                    <input type="checkbox"/>
                    <span>Keep me updated on news & offers.</span>
                </div>
                <div className="input-block">
                    <button className='btn-submit' id='btnSubmit' onClick={sendMail}>Submit</button>
                </div>
            </div>

            {/* {
                window.innerWidth < 500 ?
                    <div className="footer_content">
                        <p>Greenside Development LLC</p>
                        <p>Office No.16, A2Z Business Centre, Mai Tower ,Al Nahda 1 ,Dubai P.O. Box – 22243</p>
                        <a href="mailto:info@greenside.ae">info@greenside.ae</a>
                        <TermsAndSocial/>
                    </div> : null
            } */}
        </div>
    )
}

export default Contact;